import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTicket, faMoneyBill } from '@fortawesome/free-solid-svg-icons'
import React, { useState } from 'react'
import './AdministrarContainer.css'
import { useContext, useEffect } from 'react'
import { ToastContainer } from 'react-toastify'
import TicketContainer from '../TicketContainer/TicketContainer'
import PagoContainer from '../PagoContainer/PagoContainer'
import PropietarioContext from '../../context/PropietarioContext'
import TicketContext from '../../context/TicketContext'

const AdministrarContainer = () => {
    const { buscarPropietario } = useContext(PropietarioContext)
    const { getTickets } = useContext(TicketContext)
    const [section, setSection] = useState(1)

    useEffect(() => {
        buscarPropietario();
        getTickets();
    }, [])


    return (
        <div className='administrar-container'>
            <div className='menuAdministrar'>
                <p>Administración</p>
                <div className={section === 1 ? 'menuItem menuItemActive' : 'menuItem'} onClick={() => setSection(1)}>
                    <FontAwesomeIcon className='menuIcon' icon={faTicket} />
                    Tickets
                </div>
                <div className={section === 2 ? 'menuItem menuItemActive' : 'menuItem'} onClick={() => setSection(2)}>
                    <FontAwesomeIcon className='menuIcon' icon={faMoneyBill} />
                    Pagos
                </div>
            </div>
            <>
                {section === 1 ? (
                    <TicketContainer/>
                ) : (
                    <PagoContainer/>
                )}
                <ToastContainer
                    position="bottom-right"
                    autoClose={2000}
                    hideProgressBar
                    theme='light'
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover />
            </>
        </div>
    )
}

export default AdministrarContainer