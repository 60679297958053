import React, { createContext, useState } from "react"
import env from "react-dotenv";
import Swal from "sweetalert2";
import { toast } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';

export const GlobalContext = createContext()

const GlobalStateContext = ({ children }) => {

  const [isLoading, setIsLoading] = useState(false)
  const [loaderState, setLoaderState] = useState(1)
  const [change, setChange] = useState(true)
  const [activeModal, setActiveModal] = useState(false)
  const [activeDireccionModal, setActiveDireccionModal] = useState(false)
  const [activeEditarModal, setActiveEditarModal] = useState(false)
  const [activeDetalleModal, setActiveDetalleModal] = useState(false)
  const [activeDetalleArticuloModal, setActiveDetalleArticuloModal] = useState(false)
  const [activePdfViewer, setActivePdfViewer] = useState(false)
  const [page, setPage] = useState(1)
  const [filtersDisplayed, setFiltersDisplayed] = useState(false)
  const [swalCambio, setSwalCambio] = useState(true)

  const [idPropietario, setIdPropietario] = useState(null || localStorage.getItem('trustedUser'))
  const [accessToken, setAccessToken] = useState(null || localStorage.getItem('t'))
  const [propietario, setPropietario] = useState(null)
  const [tickets, setTickets] = useState([])
  const [ticket, setTicket] = useState({
    "idTicket": "",
    "label": "",
    "mes": "",
    "anio": "",
    "periodo": "",
    "monto": "",
    "idPropietario": "",
    "tipoPago": "",
    "tipoComprobante": "",
    "numero": "",
    "link": "",
    "estado": "",
    "fecha": "",
    "niubiz": "",
    "Nombre": "",
    "Apellido": "",
    "propietario": ""
  })
  const [detalle, setDetalle] = useState([])
  const [detalleTicketArticulos, setDetalleTicketArticulos] = useState([])
  const [userIsTrusted, setUserIsTrusted] = useState(null)
  
  const [tipoClienteFilter, setTipoClienteFilter] = useState('')
  const [nombresFilter, setNombresFilter] = useState('')
  const [desdeFilter, setDesdeFilter] = useState('2024-01-01')
  const [hastaFilter, setHastaFilter] = useState('2024-01-31')
  const [activeFilters, setActiveFilters] = useState([])
  const [filteredArticles, setFilteredArticles] = useState([])
  const [selectedFile, setSelectedFile] = useState(null);
  const [ids, setIds] = useState([])
  const [preArticles, setPreArticles] = useState([])
  
  const [propietariosList, setPropietariosList] = useState([])
  const [propietarios, setPropietarios] = useState([])
  const [propietarioFilter, setPropietarioFilter] = useState([])
  const [propietarioFilter2, setPropietarioFilter2] = useState([])
  const [detalleTickets, setDetalleTickets] = useState([])
  const [montoForm, setMontoForm] = useState('')
  const [tipoForm, setTipoForm] = useState('')
  const [bancoForm, setBancoForm] = useState('')
  const [calcularAction, setCalcularAction] = useState(false)
  const [fechaFilter, setFechaFilter] = useState(null);
  const [checkServicio, setCheckServicio] = useState(false)
  const [checkProrrateo, setCheckProrrateo] = useState(false)
  const [typePdf, setTypePdf] = useState('comprobante')

  const [filter, setFilter] = useState('')

  const restartAll = () => {
    localStorage.removeItem('trustedUser')
    localStorage.removeItem('t')

    setIsLoading(false)
    setLoaderState(1)
    setChange(true)
    setActiveModal(false)
    setActiveDireccionModal(false)
    setActiveEditarModal(false)
    setActiveDetalleModal(false)
    setActiveDetalleArticuloModal(false)

    setIdPropietario(null || localStorage.getItem('trustedUser'))
    setAccessToken(null || localStorage.getItem('trustedUser'))
    setPropietario(null)
    setTipoClienteFilter('')
    setNombresFilter('')
    setDesdeFilter('')
    setHastaFilter('')
    setUserIsTrusted(null)
    setTicketDefault()
    setDetalle(null)

    setActiveFilters([])
    setFilteredArticles([])
    setIds([])

    setFilter('')

  }

  const setTicketDefault = () => {
    setTicket({
      "Apellido": "",
      "Nombre": "",
      "anio": "",
      "estado": "",
      "fecha": "",
      "fecha_fin": "",
      "filename": "",
      "idPropietario": "",
      "idTicket": "",
      "idTramo": "",
      "label": "",
      "link": "",
      "mes": "",
      "monto": "",
      "montoOriginal": "",
      "niubiz": "",
      "numero": "",
      "periodo": "",
      "propietario": "",
      "tipoComprobante": "",
      "tipoPago": ""      
    })
  }

  const resetPago = () => {
    setMontoForm('')
    setTipoForm('')
    setBancoForm('')
  }

  const formatStrings = (str) =>{
    if(!str) return str
    return str
    .replaceAll('Ã±','ñ')
    .replaceAll('Ã¡','á')
    .replaceAll('Ã','í')
    .replaceAll('í³','ó')
  }

  const handleFilters = () => {
    setPage(1)
  }

  const encodePetition = (parameters, formBody = []) => {
    for (let property in parameters) {
      let encodedKey = encodeURIComponent(property)
      let encodedValue = encodeURIComponent(parameters[property])
      formBody.push(encodedKey + "=" + encodedValue)
    }
    return formBody.join("&")
  }

  const buscarPropietario = () => {
    fetch(env.API_URL+"/user/"+localStorage.getItem('trustedUser'), {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "Authorization": "Bearer "+accessToken
      }
    })
      .then((res) => res.json())
      .then((res) => {
        setPropietario(res)
      })
  }
      
  const updateFechas = () => {
      return true;
  }

  return (
    <GlobalContext.Provider
      value={{
        isLoading, setIsLoading,
        loaderState, setLoaderState,
        change, setChange,
        propietario, setPropietario,
        userIsTrusted, setUserIsTrusted,
        encodePetition, buscarPropietario,
        tickets, setTickets, 
        ticket, setTicket, setTicketDefault,
        idPropietario, setIdPropietario, accessToken, setAccessToken,
        filteredArticles, setFilteredArticles,
        filter, setFilter,
        handleFilters,
        activeFilters, setActiveFilters,
        activeModal, setActiveModal,
        activeDireccionModal, setActiveDireccionModal,
        activeEditarModal, setActiveEditarModal,
        activeDetalleModal, setActiveDetalleModal,
        detalleTicketArticulos,
        activeDetalleArticuloModal, setActiveDetalleArticuloModal, 
        detalle, setDetalle, 
        activePdfViewer, setActivePdfViewer,
        ids, setIds,
        restartAll,
        page, setPage,
        filtersDisplayed, setFiltersDisplayed,
        swalCambio, setSwalCambio,
        formatStrings,
        tipoClienteFilter, setTipoClienteFilter,
        nombresFilter, setNombresFilter,
        desdeFilter, setDesdeFilter,
        hastaFilter, setHastaFilter,
        selectedFile, setSelectedFile,
        propietarios, 
        propietariosList,
        propietarioFilter, setPropietarioFilter,
        fechaFilter, setFechaFilter,
        propietarioFilter2, setPropietarioFilter2,
        detalleTickets, setDetalleTickets,
        montoForm, setMontoForm,
        tipoForm, setTipoForm,
        bancoForm, setBancoForm,
        setCalcularAction,
        preArticles, setPreArticles,
        setCheckServicio, setCheckProrrateo, updateFechas, typePdf, setTypePdf,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalStateContext;